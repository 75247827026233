import { createSlice, configureStore } from "@reduxjs/toolkit";

export const DEFAULT_AFFILIATE_URL = 'https://www.h32lucky.com/vi-vn';

const mainSiteConfigSlice = createSlice({
    name: "mainSiteConfig",
    initialState: {
        affiliateUrl: DEFAULT_AFFILIATE_URL,
    },
    reducers: {
        setAffiliateUrl(state, action) {
            const affiliateUrl = action.payload || DEFAULT_AFFILIATE_URL
            return { ...state, affiliateUrl };
        },
    },
});

export default mainSiteConfigSlice;

export const mainSiteConfigActions = mainSiteConfigSlice.actions;